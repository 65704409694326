import React from 'react'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import deliveryImg from '../../Data/Image/delivery.png'
import gojekImg from '../../Data/Image/gojek.png'
import grabImg from '../../Data/Image/grab.png'



const FormOutlet = (props) => {
    const outlet = props.outletSelect.outlet
    const handleClose = () => {
        props.setOpen(false);
    };
    const handleDelivery = () => {
        props.setIsFormOrder(true)
        handleClose()
    }
    return (
        <Dialog open={props.open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogContent>
                <img src={deliveryImg} className=" d-block mx-auto img-fluid w-50" alt="Orderan" />
                <Button onClick={handleDelivery} variant="contained" size="medium" fullWidth color="primary" style={{ margin: "1px 0px" }}>Delivery</Button>
                {outlet.gojek && (
                    <Button onClick={() => window.open(`${outlet.gojek}`, '_blank')} style={{ margin: "1px 0px" }} variant="contained" size="medium" fullWidth className="btn3">Ordern On &nbsp;<img src={gojekImg} className="img-btn" alt="Go-jek" /></Button>
                )}
                {outlet.grab && (
                    <Button onClick={() => window.open(`${outlet.grab}`, '_blank')} variant="contained" size="medium" style={{ margin: "1px 0px" }} fullWidth className="btn3">Ordern On &nbsp;<img src={grabImg} className="img-btn" alt="Go-jek" /></Button>
                )}
            </DialogContent>
        </Dialog>
    )
}

export default FormOutlet;