import React, { useState, useEffect } from "react";
import {
    Toolbar, Typography, IconButton, Dialog, AppBar, Slide, Container
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import numberWithCommasEmpty from '../../Helper/numberWithCommasEmpty'
import Box from '@material-ui/core/Box';
import { Row, Col } from 'react-bootstrap';
import noImage from '../../Data/Image/noImage.png'
import Skeleton from 'react-loading-skeleton';
import mOrder from '../../Controller/order'
import ModalOrderCart from './ModalOrderCart'



const ModalOrder = (props) => {
    const classes = useStyles();
    const authData = props.authData
    const rowLoad = [0, 1, 2, 3, 4, 5]
    const outlet = props.outletSelect.outlet
    const [isLoading, setIsLoading] = useState(true)
    const [listMenu, setListMenu] = useState([])
    const [listKategori, setListKategori] = useState([])
    const [shoppingCart, setShoppingCart] = useState([])
    const [isOrder, setIsOrder] = useState(false)


    const handleClose = () => {
        props.setOpen(false);
    }

    useEffect(() => {
        mOrder.listMenu(authData.clientID, authData.token, authData.hp, outlet.kodeoutlet).then((res) => {
            setListMenu(res.listMenu)
            setListKategori(res.listKategori)
        }).catch(err => {
            alert(err[0].message)
        }).finally(() => setIsLoading(false))
        return () => {
        }
    }, [authData.clientID, authData.token, authData.hp, outlet.kodeoutlet, isLoading])

    const handleItemClick = (item) => {
        const newCart = []
        const listCart = [...shoppingCart, { ...item, qty: 1 }]
        listCart.reduce((res, val) => {
            if (!res[val.kodebarang]) {
                res[val.kodebarang] = { kodebarang: val.kodebarang, namabarang: val.namabarang, qty: 0, harga: val.harga, jumlah: 0, gambar: val.gambar, manajemenPrinter: val.manajemenPrinter }
                newCart.push(res[val.kodebarang])
            }
            res[val.kodebarang].qty += val.qty
            res[val.kodebarang].jumlah = res[val.kodebarang].qty * res[val.kodebarang].harga
            return res
        }, {})
        setShoppingCart(newCart)
    }

    return (
        <Dialog fullScreen open={props.open} TransitionComponent={Transition}>
            {!isOrder ? (<>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                            <ArrowBackIcon />
                            <Typography variant="h6" className={classes.title}>{outlet.namaoutlet}</Typography>
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <Container className="DialogFont bodyDialog">
                    {listKategori.map((k, indexK) => (
                        <div key={indexK}>
                            <Row><Col> <h5 className="textColorprimary">{k.namakategori}</h5></Col></Row>
                            {listMenu.filter(b => b.namakategori === k.namakategori).map((b, indexB) => (
                                <Row key={indexB} onClick={handleItemClick.bind(this, b)} className="tangan">
                                    <Col xs={4}>
                                        <img src={b.gambar || noImage} alt="Outlet" className="d-block mx-auto img-fluid imgMenu" />
                                    </Col>
                                    <Col xs={8}>
                                        <Box display="flex" p={1} style={{ marginTop: 30 }}>
                                            <Box p={1} flexGrow={1} >{b.namabarang}</Box>
                                            <Box p={1} >
                                                {numberWithCommasEmpty(b.harga)}
                                            </Box>
                                        </Box>
                                    </Col>
                                    <Col className="border-btm"></Col>
                                </Row>
                            ))}
                        </div>
                    ))}
                    {isLoading && rowLoad.map((el, i) => (
                        <Row key={i}>
                            <Col xs={4}>
                                <Skeleton height={65} />
                            </Col>
                            <Col xs={8}>
                                <Box display="flex" p={1} style={{ marginTop: 12 }}>
                                    <Box p={1} flexGrow={1} > <Skeleton width={100} /></Box>
                                    <Box p={1} >
                                        <Skeleton width={40} />
                                    </Box>
                                </Box>
                            </Col>
                            <Col className="border-btm"></Col>
                        </Row>
                    ))}
                    {!isLoading && (listMenu.length <= 0 || listKategori.length <= 0) && (
                        <Col xs={12} className="mgTop5">
                            <Col xs={12}>
                                <h6 className="text-center">Belum ada list menu yang di tampilkan</h6>
                            </Col>
                        </Col>
                    )}
                    <Row className="bgB60"></Row>
                </Container>
                <div >
                    <div className={classes.btnOrder} onClick={() => setIsOrder(true)}>
                        <Box display="flex" p={1} className="btn-card">
                            <Box p={1} flexGrow={1} >
                                {shoppingCart.length} Item | {numberWithCommasEmpty(shoppingCart.map(el => el.jumlah).reduce((a, b) => a + b, 0))}
                            </Box>
                            <Box p={1} >
                                <ShoppingCartIcon />
                            </Box>
                        </Box>
                    </div>
                </div>
            </>) : (
                    <ModalOrderCart setIsOrder={setIsOrder} authData={authData} shoppingCart={shoppingCart} outlet={outlet} urlBack={props.urlBack}/>
                )}

        </Dialog>
    )
}
export default ModalOrder;
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const useStyles = makeStyles(theme => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    root: {
        width: '100%',
        marginTop: theme.spacing(1),
        overflowX: 'auto',
        overflowY: 'hidden',
    },
    btnOrder: {
        margin: theme.spacing(),
        position: "fixed",
        bottom: theme.spacing(1),
        zIndex: 2,
        width: window.innerWidth - 18,
        cursor: "pointer"
    },
}));