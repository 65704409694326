import GQL from '../GQL';
import GQLHomanPOS from '../GQL2';

const point = {
    listOutlet: (clientID, token, username) => {
        return new Promise((resolve, reject) => {
            const query = `
                        query{
                            listOutletWeb(username:"${username}"){
                                id
                                outlet{
                                  kodeoutlet
                                  namaoutlet
                                  gambar
                                }
                            }
                        }
                    `
            GQL(query, clientID, token).then(resp => {
                if (resp.errors) return (reject(resp.errors));;
                if (resp && resp.data && resp.data.listOutletWeb) {
                    return (resolve(resp.data.listOutletWeb));
                } else {
                    return (reject(new Error("Gagal load data")))
                }
            }).catch(err => reject(err));
        })
    },
    listPoint: (clientID, token, username) => {
        return new Promise((resolve, reject) => {
            const query = `
                        query{
                            listCekPoint{
                                kodeoutlet
                                poin
                            }
                        }
                    `
            GQLHomanPOS(query, clientID, token).then(resp => {
                if (resp.errors) return (reject(resp.errors));;
                if (resp && resp.data && resp.data.listCekPoint) {
                    return (resolve(resp.data.listCekPoint));
                } else {
                    return (reject(new Error("Gagal load data")))
                }
            }).catch(err => reject(err));
        })
    },
    simpanPoint: (clientID, token, kodeoutlet, noinvoice) => {
        return new Promise((resolve, reject) => {
            const query = `
                        mutation{
                            getPoint(kodeoutlet:"${kodeoutlet}", noinvoice:"${noinvoice}"){
                                success
                                info
                            }
                        }
                    `
            GQLHomanPOS(query, clientID, token).then(resp => {
                if (resp.errors) return (reject(resp.errors));
                if (resp && resp.data && resp.data.getPoint) {
                    return (resolve(resp.data.getPoint));
                } else {
                    return (reject(new Error("Gagal simpan data")))
                }
            }).catch(err => reject(err));
        })
    },
}

export default point;