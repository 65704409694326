import React, { useState, useContext, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AuthContext from '../context/auth-context'
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import CropFreeIcon from '@material-ui/icons/CropFree';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import HomeIcon from '@material-ui/icons/Home';
import StoreIcon from '@material-ui/icons/Store';
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import { useHistory } from "react-router-dom";
import ModalQR from './ModalQR'
import SettingsOverscanIcon from '@material-ui/icons/SettingsOverscan';



const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    stickToBottom: {
        width: '100%',
        position: 'fixed',
        bottom: 0,
        zIndex: 99
    },
}));

const MyNavbar = (props) => {
    let history = useHistory();
    const { userLogin, clientID, token } = useContext(AuthContext);
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [value, setValue] = useState('home');
    const [isQR, setIsQR] = useState(false)

    const logOut = () => {
        props.onLogout()
        handleClose()
    }
    const handleQR = () => {
        setIsQR(true)
        handleClose()
    }
    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleChange = (event, newValue) => {
        setValue(newValue);
        history.push(`/${newValue}`)
    };
    useEffect(() => {
        const path = props.pathname
        if(path === "/" || path === "/home"){
          return setValue("home")
        }else if(path === "/order" || path === "/order-now"){
            return  setValue("order")
        }else if(path === "/rewards"){
            return  setValue("rewards")
        }else if(path === "/point"){
            return  setValue("point")
        }else if(path === "/kupon"){
            return  setValue("kupon")
        }else if(path === "/outlet"){
            return  setValue(" ")
        }
        return () => {
            
        }
    }, [props.pathname])
    return (
        <React.Fragment>
            <CssBaseline />
            <AppBar
                position="fixed"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: open,
                })}
            >
                <Toolbar position="static">
                    <Typography variant="h6" className={classes.title}>iPOS Delivery</Typography>
                    {props.isLogin &&
                        <>
                            <IconButton
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                color="inherit"
                                onClick={handleMenu}
                            >
                                <AccountCircle />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={open}
                                onClose={handleClose}
                            >
                                <MenuItem onClick={handleQR}><CropFreeIcon color="inherit" /> QR Code</MenuItem>
                                <MenuItem onClick={handleClose}><AccountCircle color="inherit" /> Profile (<i>{userLogin}</i>)</MenuItem>
                                <MenuItem onClick={logOut} className="colorRed"> <ExitToAppIcon />Log Out</MenuItem>
                            </Menu>
                        </>
                    }
                </Toolbar>
            </AppBar>
            {props.isLogin &&
                <BottomNavigation value={value} onChange={handleChange} className={classes.stickToBottom} showLabels>
                    <BottomNavigationAction label="Home" value="home" icon={<HomeIcon />} />
                    <BottomNavigationAction label="Order" value="order" icon={<StoreIcon />} />
                    <BottomNavigationAction label="Point" value="point" icon={<SettingsOverscanIcon />} />
                    <BottomNavigationAction label="Rewards" value="rewards" icon={<CardGiftcardIcon />} />
                    <BottomNavigationAction label="Kupon" value="kupon" icon={<ConfirmationNumberIcon />} />
                </BottomNavigation>
            }
            {props.isLogin && isQR && <ModalQR open={isQR} setOpen={setIsQR} clientID={clientID} token={token} userLogin={userLogin}/>}
        </React.Fragment>
    );
}
export default MyNavbar;
