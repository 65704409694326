import React, { useContext, useEffect, useState } from 'react'
import AuthContext from '../../context/auth-context'
import { Row, Col } from 'react-bootstrap';
import { FaChevronLeft } from 'react-icons/fa'
import { makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import mControl from '../../Controller/outlet'
import FormOutlet from './FormOutlet'
import SweetAlert from "react-bootstrap-sweetalert";
import ListOutlet from './ListOutlet'

const useStyles = makeStyles((theme) => ({
    fab: {
        margin: theme.spacing(), // You might not need this now
        position: "fixed",
        bottom: theme.spacing(8),
        right: theme.spacing(3),
        zIndex: 2
    },
    root: {
        display: 'flex',
    }

}));

const Outlet = (props) => {
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(true)
    const [listOutlet, setListOutlet] = useState([])
    const authData = useContext(AuthContext);
    const [isForm, setIsForm] = useState(false)
    const [isAlert, setIsAlert] = useState(false);
    const [textAlert, setTextAlert] = useState("");

    useEffect(() => {
        mControl.listOutlet(authData.clientID, authData.token, authData.hp).then((res) => {
            setListOutlet(res)
        }).catch(err => {
            alert(err[0].message)
        }).finally(() => setIsLoading(false))
        return () => {

        }
    }, [authData.clientID, authData.token, authData.hp, isLoading])
    return (
        <React.Fragment>
            <Fab color="primary" aria-label="Add" className={classes.fab} onClick={() => setIsForm(true)}>
                <AddIcon />
            </Fab>
            <Row>
                <Col xs={12}>
                    <h4 className="border-bottom paddingBottom5 "><FaChevronLeft onClick={() => props.history.goBack()} className="btnBack" />List Outlet</h4>
                </Col>
            </Row>
            <Row>
                {!isLoading && listOutlet.length <= 0 && (
                    <Col xs={12} className="mgTop5">
                        <Card className={classes.root}>
                            <Col xs={12}>
                                <CardContent>
                                    <h6 className="text-center">Anda belum ada mendaftarkan outlet</h6>
                                </CardContent>
                            </Col>
                        </Card>
                    </Col>
                )}
            <ListOutlet listOutlet={listOutlet} isLoading={isLoading}/>
            </Row>
            {isForm && <FormOutlet open={isForm} setOpen={setIsForm} authData={authData} setIsAlert={setIsAlert} setIsLoading={setIsLoading} setTextAlert={setTextAlert} />}
            {isAlert && (
                <SweetAlert
                    title="Perhatian"
                    onConfirm={() => setIsAlert(false)}
                    success
                    show={isAlert}
                >
                    {" "}
                    {textAlert}
                </SweetAlert>
            )}
            <Row className="bgB60"></Row>
        </React.Fragment>
    )
}

export default Outlet;