import React, { useEffect, useState } from 'react'
import { Row, Col } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import noImage from '../../Data/Image/noImage.png'
import mOrder from '../../Controller/order'
import moment from 'moment-timezone'
import numberWithCommas from '../../Helper/numberWithCommasEmpty';
import { Button } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import DialogInfo from '../../Dialog/DialogInfo'



const OrderHistory = (props) => {
    const rowLoad = [0, 1]
    const [isLoading, setIsLoading] = useState(false)
    const [listData, setListData] = useState([])
    const authData = props.authData
    const [isSimpan, setIsSimpan] = useState(false)
    const [isAlert, setIsAlert] = useState(false);
    const [textAlert, setTextAlert] = useState('');

    useEffect(() => {
        mOrder.riwayatOrder(authData.clientID, authData.token, authData.hp).then((res) => {
            setListData(res)
        }).catch(err => {
            alert(err[0].message)
        }).finally(() => setIsLoading(false))
        return () => {
        }
    }, [authData.clientID, authData.token, authData.hp, isLoading])
    const handleFinish = (item) => {
        setIsSimpan(true)
        mOrder.setDelivered(authData.clientID, authData.token, item.outlet.kodeoutlet, item.kodeorderan).then((res) => {
            if (res.success) {
                setTextAlert(res.info)
                setIsAlert(true)
            } else {
                alert(res.info)
            }
        }).catch(err => {
            alert(err[0].message)
        }).finally(() => {
            setIsSimpan(false)
            setIsLoading(true)
        })
    }
    return (
        <React.Fragment>
            <Row>
                <Col xs={12}>
                    <h5 className="textColorprimary mgTop5">Order History</h5>
                </Col>
            </Row>
            <Row>
                {!isLoading && listData.length <= 0 && (
                    <Col xs={12}>
                        <Card className="flex">
                            <Col xs={12}>
                                <CardContent>
                                    <h6 className="text-center">Anda belum ada melakukan orderan. Orderan anda akan tampil disini setelah melakukan orderan.</h6>
                                </CardContent>
                            </Col>
                        </Card>
                    </Col>
                )}
                {!isLoading && listData.map((el, i) => {
                    let listBarang = ''
                    let jumlahItem = 0
                    for (const i of el.item) {
                        if (jumlahItem > 0) listBarang += `, `
                        listBarang += `${i.qty} ${i.namabarang} `
                        jumlahItem += 1
                    }
                    let statusName = '';
                    switch (el.statusid) {
                        case 1:
                            statusName = 'Ordered';
                            break;
                        case 2:
                            statusName = 'Accepted';
                            break;
                        case 3:
                            statusName = "On Delivery";
                            break;
                        case 4:
                            statusName = "Finish";
                            break;
                        case 8:
                            statusName = "Rejected";
                            break
                        default:
                            statusName = 'Ordered';
                    }
                    return (
                        <Col xs={12} key={i} className="mgTop5">
                            <Card className="flex">
                                <Col xs={4}>
                                    <img src={el.outlet.gambar || noImage} alt="Outlet" className="d-block mx-auto img-fluid imgOutlet" />
                                </Col>
                                <Col xs={8}>
                                    <CardContent>
                                        <h6>{el.outlet.namaoutlet} <br />
                                            <small>{moment(el.tanggal).format("DD-MM-YYYY")}</small><br />
                                            <small><b>{statusName}</b></small><br />
                                            <small><i>{listBarang}</i></small>
                                        </h6>
                                        <span><b>{jumlahItem} item{jumlahItem > 1 ? 's' : ''} | {numberWithCommas(el.item.map(el => el.jumlah).reduce((a, b) => a + b, 0))}</b></span>
                                        {el.statusid === 3 && (
                                            <Button variant="contained" size="medium" color="primary" onClick={handleFinish.bind(this, el)}>{isSimpan ? <CircularProgress /> : " Accept Order"}</Button>
                                        )}

                                    </CardContent>
                                </Col>
                            </Card>
                        </Col>
                    )
                })}
                {isLoading && rowLoad.map((el, i) => (
                    <Col xs={12} key={i} className="mgTop5">
                        <Card className="flex">
                            <Col xs={4}>
                                <Skeleton height={110} style={{ margin: 10 }} />
                            </Col>
                            <Col xs={8}>
                                <CardContent>
                                    <h5> <Skeleton width={180} /></h5>
                                    <span><Skeleton width={160} /></span><br />
                                    <span><Skeleton width={140} /></span>
                                </CardContent>
                            </Col>
                        </Card>
                    </Col>
                ))}
                {isAlert && (
                    <DialogInfo open={isAlert} setOpen={setIsAlert} text={textAlert} />
                )}
            </Row>
            <Row className="bgB60"></Row>
        </React.Fragment>
    )
}

export default OrderHistory;