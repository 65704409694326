import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Modal, Button, DialogActions, DialogTitle, DialogContent } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

const ModalInfo = (props) => {
    const classes = useStyles();
    const handleClose = () => {
        props.setOpen(false);
    }
    const closeModal = () => {
        if(props.closeModal && props.statusSave){
            props.closeModal(false) || handleClose()
        }else{
            handleClose()
        }
    }
    return (
        <Modal
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            className={classes.modal}
            open={props.open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
            timeout: 500,
            }} 
        >
            <Fade in={props.open}>
            <div className={`${classes.paper} bgModal`}>
                <DialogTitle className="colorWhite">Perhatian</DialogTitle>
                <DialogContent className="colorWhite">
                    <span dangerouslySetInnerHTML={{ __html: props.text }}></span>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeModal} color="primary" autoFocus>Oke</Button>
                </DialogActions>
            </div>
            </Fade>
            
        </Modal>
    )
}
export default ModalInfo;

const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        position: 'absolute',
        width: 350,
        boxShadow: theme.shadows[5],
        padding: 0,
    },
}));
