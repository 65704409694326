import GQL from '../../GQL';

const Profil = {
    loadProfil: (clientID, token, email) => {
        return new Promise((resolve, reject) => {
            const query = `
                query {
                    userDetail(email:"${email}"){
                        email
                        nama
                        noHp
                        password
                        salt
                    }
                }
            `
            GQL(query, clientID, token).then(resp => {
                if (resp.errors) throw resp.errors;
                if (resp.data && resp.data.userDetail) return (resolve(resp.data.userDetail));
                return (resolve([]))
            }).catch(err => {
                return (reject(err));
            })
        })
    },
    updateProfil: (clientID, token, data) => {
        return new Promise((resolve, reject) => {
            const query = `
                mutation{
                    UpdateUser(input:{email:"${data.email}",nama:"${data.nama}", noHp:"${data.noHp}"})
                }
            `
            GQL(query, clientID, token).then(resp => {
                if (resp.errors) return (reject(resp.errors));;
                if (resp.data && resp.data.UpdateUser !== undefined) {
                    return (resolve(resp.data.UpdateUser));
                } else {
                    return (reject(new Error("Gagal menyimpan data")))
                }
            }).catch(err => reject(err))
        })
    },
    updatePassword: (clientID, token, salt, email, password) => {
        return new Promise((resolve, reject) => {
            const query = `
                mutation{
                    resetPasswordUser(salt:"${salt}",email:"${email}", password:"${password}")
                }
            `
            GQL(query, clientID, token).then(resp => {
                if (resp.errors) return (reject(resp.errors));
                if (resp.data && resp.data.resetPasswordUser !== undefined) {
                    return (resolve(resp.data.resetPasswordUser));
                } else {
                    return (reject(new Error("Gagal menyimpan data")))
                }
            }).catch(err => reject(err))
        })
    },
}

export default Profil;