import React, {useState, useEffect} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

const MCatat = props => {
    const [catatan, setCatatan] = useState('')
    useEffect(() => {
        setCatatan(props.catatan)
        return () => {

        }
    }, [props.catatan])


    const handleClose = () => {
        props.close()
    };
    const handleSimpan = () => {
        if (catatan.length <= 200) {
            props.handleCatatanChange(catatan)
            handleClose()
          } else {
            alert("Notes are too long")
          }
    }
    return (
        <Dialog open={props.open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Catatan</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Please input your notes (e.g: make it spicy)"
                    fullWidth
                    value={catatan}
                    onChange={(c) => setCatatan(c.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="default">Cancel</Button>
                <Button onClick={handleSimpan} color="primary">Simpan</Button>
            </DialogActions>
        </Dialog>
    )
}
export default MCatat