import React, { useContext } from 'react'
import AuthContext from '../../context/auth-context'
import { Row, Col } from 'react-bootstrap';
import OrderHistory from './OrderHistory'
import deliveryImg from '../../Data/Image/delivery.png'
import { Button } from '@material-ui/core';




const Order = (props) => {
  const authData = useContext(AuthContext);


  return (
    <React.Fragment>
      <img src={deliveryImg} className=" d-block mx-auto img-fluid" alt="Orderan" />
      <Row>
      <Col xs={12} className="text-center">
          <Button variant="contained" size="medium" fullWidth color="primary" onClick={() => props.history.push('/order-now')}>Order Now</Button>
        </Col>
      </Row>
      <OrderHistory authData={authData} />
    </React.Fragment>
  )
}

export default Order;