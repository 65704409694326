import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import SweetAlert from "react-bootstrap-sweetalert";
import DialogTitle from '@material-ui/core/DialogTitle';
import QrReader from 'react-qr-reader'
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import mControl from '../../Controller/point'

const ModalScan = (props) => {
    const outlet = props.outlet
    const authData = props.authData
    const [isScan, setIsScan] = useState(true)
    const [isAlert, setIsAlert] = useState(false);
    const [textAlert, setTextAlert] = useState("");
    const [nomorInv, setNomorInv] = useState("")
    const [isProsesSimpan, setIsProsesSimpan] = useState(false)



    const handleClose = () => {
        props.setOpen(false)
    };
    const handleScan = data => {
        if (data) {
            prosesSimpan(data)
        }
    }
    const handleError = err => {
        setIsAlert(true)
        setTextAlert(err.message)
    }
    const handleSimpan = () => {
        if (nomorInv.length <= 0) {
            setIsAlert(true)
            setTextAlert("Wajib isi nomor invoice")
            return
        }
        prosesSimpan(nomorInv)
    }
    const prosesSimpan = data => {
        setIsProsesSimpan(true)
        mControl.simpanPoint(authData.clientID2, authData.token, outlet.outlet.kodeoutlet, data).then((resp) => {
            if (resp.success) {
                props.setTextAlert(resp.info)
                props.setIsAlert(true)
                props.setIsLoading(true)
                handleClose()
            } else {
                setTextAlert(resp.info)
                setIsAlert(true)
            }
        }).catch(err => {
            setTextAlert(err[0].message)
            setIsAlert(true)
            setIsProsesSimpan(false)
        })
    }
    return (
        <Dialog
            open={props.open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">Scan Struk</DialogTitle>
            <h6 style={{ marginTop: 5 }} className="text-center">{outlet.outlet.namaoutlet}</h6>
            {isProsesSimpan ? (
                <h6 className="text-center"><CircularProgress /> <br /> <small>Harap tunggu, sedang menyimpan data</small></h6>
            ) : (
                    <>
                        <DialogContent>
                            {isScan ? <QrReader
                                delay={300}
                                onError={handleError}
                                onScan={handleScan}
                                style={{ width: '100%' }}
                            /> : (
                                    <>

                                        <TextField
                                            autoFocus
                                            margin="dense"
                                            id="name"
                                            label="Masukan Nomor Invoice"
                                            fullWidth
                                            value={nomorInv}
                                            onChange={(e) => setNomorInv(e.target.value)}
                                            variant="filled"
                                        />
                                    </>
                                )}
                            <h6 className="textColorprimary tangan text-center" onClick={() => setIsScan(!isScan)}>{isScan ? "Input manual nomor invoice" : "Scan Barcode"}</h6>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose} color="default">Close</Button>
                            {!isScan && <Button onClick={handleSimpan} color="primary" autoFocus>Simpan</Button>}
                        </DialogActions>
                    </>
                )}
            {isAlert && (
                <SweetAlert
                    title="Perhatian"
                    onConfirm={() => setIsAlert(false)}
                    show={isAlert}
                >
                    {" "}
                    {textAlert}
                </SweetAlert>
            )}
        </Dialog>
    )
}

export default ModalScan;