import React, { useState } from 'react'
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import SweetAlert from "react-bootstrap-sweetalert";
import mControl from '../../Controller/outlet'



const FormOutlet = (props) => {
    const authData = props.authData
    const [form, setForm] = useState({ kodeoutlet: '' })
    const [isAlert, setIsAlert] = useState(false);
    const [textAlert, setTextAlert] = useState("");

    const handleClose = () => {
        props.setOpen(false);
    };

    const handleChange = (ev) => {
        var newState = { ...form }
        var value = ev.target.value;
        const key = ev.target.id || ev.target.name;
        newState[key] = value;
        setForm(newState)
    }
    const simpan = () => {
        if (form.kodeoutlet) {
            mControl.simpanOutlet(authData.clientID, authData.token, authData.hp, form.kodeoutlet).then((resp) => {
                if (resp.success) {
                    props.setTextAlert(resp.info)
                    props.setIsAlert(true)
                    props.setIsLoading(true)
                    handleClose()
                } else {
                    setTextAlert(resp.info)
                    setIsAlert(true)
                }
            }).catch(err => {
                setTextAlert(err[0].message)
                setIsAlert(true)
            })
        } else {
            setTextAlert("Wajib masukan kode outlet")
            setIsAlert(true)
            return
        }
        return
    }
    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            simpan()
        }
    }
    return (
        <Dialog open={props.open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Tambah Outlet</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    id="kodeoutlet"
                    label="Masukan kode outlet"
                    fullWidth
                    value={form.kodeoutlet}
                    onChange={handleChange}
                    type="number"
                    onKeyPress={handleKeyPress}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={simpan} color="primary">Simpan</Button>
            </DialogActions>
            {isAlert && (
                <SweetAlert
                    title="Perhatian"
                    onConfirm={() => setIsAlert(false)}
                    danger
                    show={isAlert}
                >
                    {" "}
                    {textAlert}
                </SweetAlert>
            )}
        </Dialog>
    )
}

export default FormOutlet;