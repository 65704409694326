import React, { useContext, useEffect, useState } from 'react'
import AuthContext from '../../context/auth-context'
import { Row, Col } from 'react-bootstrap';
import { FaChevronLeft } from 'react-icons/fa'
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import mControl from '../../Controller/point'
import noImage from '../../Data/Image/noImage.png'
import Skeleton from 'react-loading-skeleton';
import ModalScan from './Modal.Scan'
import SweetAlert from "react-bootstrap-sweetalert";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    }

}));

const Point = (props) => {
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(true)
    const [listOutlet, setListOutlet] = useState([])
    const [isOpenScan, setIsOpenScan] = useState(false)
    const [detOutlet, setDetOutlet] = useState({})
    const authData = useContext(AuthContext);
    const [isAlert, setIsAlert] = useState(false);
    const [textAlert, setTextAlert] = useState("");

    useEffect(() => {
        const outlet = new Promise((resolve, reject) => {
            mControl.listOutlet(authData.clientID, authData.token, authData.hp).then((res) => {
                return resolve(res)
            }).catch(err => {
                alert(err[0].message)
            })
        })
        const point = new Promise((resolve, reject) => {
            if (!authData.clientID2) return alert("Harap ulang login, tekan logout lalu login kembali")
            mControl.listPoint(authData.clientID2, authData.token).then((res) => {
                return resolve(res)
            }).catch(err => {
                alert(err[0].message)
            })
        })
        Promise.all([outlet, point]).then((values) => {
            const outlet = values[0]
            const point = values[1]
            const listData = []
            outlet.map(el => {
                const ada = point.find(f => f.kodeoutlet === el.outlet.kodeoutlet)
                if (ada) {
                   return listData.push({
                        ...el,
                        point: ada.poin
                    })
                } else {
                    return listData.push({
                        ...el,
                        point: 0
                    })
                }
            })
            setListOutlet(listData)
        }).catch(err => {
            alert(err)
        }).finally(() => setIsLoading(false))

        return () => {

        }
    }, [authData.clientID2, authData.clientID, authData.token, authData.hp, isLoading])
    const rowLoad = [0, 1, 2]

    const hanldeSelect = data => {
        setIsOpenScan(true)
        setDetOutlet(data)
    }
    return (
        <React.Fragment>
            <Row>
                <Col xs={12}>
                    <h4 className="border-bottom paddingBottom5 "><FaChevronLeft onClick={() => props.history.goBack()} className="btnBack" />Point</h4>
                    <h6 className="textColorprimary mgTop5">Pilih Outlet yang anda ingin masukan poin!</h6>
                </Col>
            </Row>
            <Row>
                {!isLoading && listOutlet.length <= 0 && (
                    <Col xs={12} className="mgTop5">
                        <Card className={classes.root}>
                            <Col xs={12}>
                                <CardContent>
                                    <h6 className="text-center">Anda belum ada mendaftarkan outlet</h6>
                                </CardContent>
                            </Col>
                        </Card>
                    </Col>
                )}
                {!isLoading && listOutlet.map((el, i) => (
                    <Col xs={12} key={i} className="mgTop5 tangan" onClick={hanldeSelect.bind(this, el)}>
                        <Card className="flex">
                            <Col xs={4}>
                                <img src={el.outlet.gambar || noImage} alt="Outlet" className="d-block mx-auto img-fluid imgOutlet" />
                            </Col>
                            <Col xs={8}>
                                <CardContent>
                                    <h5>{el.outlet.namaoutlet}</h5>
                                    <span>Point Anda: <b>{el.point || 0}</b></span>
                                </CardContent>
                            </Col>
                        </Card>
                    </Col>
                ))}
                {isLoading && rowLoad.map((el, i) => (
                    <Col xs={12} key={i} className="mgTop5">
                        <Card className="flex">
                            <Col xs={4}>
                                <Skeleton height={110} style={{ margin: 10 }} />
                            </Col>
                            <Col xs={8}>
                                <CardContent>
                                    <h5> <Skeleton width={180} /></h5>
                                    <span><Skeleton width={160} /></span><br />
                                    <span><Skeleton width={140} /></span>
                                </CardContent>
                            </Col>
                        </Card>
                    </Col>
                ))}
            </Row>
            <Row className="bgB60"></Row>
            {isOpenScan && <ModalScan open={isOpenScan} setOpen={setIsOpenScan} authData={authData} outlet={detOutlet} setIsAlert={setIsAlert} setIsLoading={setIsLoading} setTextAlert={setTextAlert} />}
            {isAlert && (
                <SweetAlert
                    title="Berhasil"
                    onConfirm={() => setIsAlert(false)}
                    success
                    show={isAlert}
                >
                    {" "}
                    {textAlert}
                </SweetAlert>
            )}
        </React.Fragment>
    )
}

export default Point;