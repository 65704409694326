import React from 'react'
//import React, { useContext, useEffect, useState } from 'react'
import Carousel from 'react-bootstrap/Carousel'
// import AuthContext from '../../context/auth-context'
import { Container, Row, Col } from 'react-bootstrap';
import OrderNowImg from '../../Data/Image/menu/OrderNow.png'
import RewardsImg from '../../Data/Image/menu/Rewards.png'
import KuponImg from '../../Data/Image/menu/Kupon.png'
import OutletsImg from '../../Data/Image/menu/Outlets.png'

type HomeProps = {
    history: {
        push(url: string): void;
    }
}


const Home = (props: HomeProps) => {
    //const { clientID, token, userLogin } = useContext(AuthContext);
    const slider = [
        'https://via.placeholder.com/500x200?text=Makanan1',
        'https://via.placeholder.com/500x200?text=Makanan2',
        'https://via.placeholder.com/500x200?text=Makanan3'
    ]

    return (
        <React.Fragment>
            <Row>
                <div className="content-full mgTopMin5">
                    <Carousel>
                        {slider.map((slideImg, i) => (
                            <Carousel.Item key={i}>
                                <img
                                    className="d-block w-100 img-slide"
                                    src={slideImg}
                                    alt="Makanan 1"
                                />
                            </Carousel.Item>
                        ))}
                    </Carousel>
                </div>
            </Row>
            <Container style={{ marginTop: 10 }}>
                <Row className="mgLRMin30 bgB60">
                    <Col xs={6} sm={3}>
                        <img onClick={() => props.history.push('/order')} src={OrderNowImg}  alt="Order Now" className="pdImage d-block mx-auto img-fluid w-100 tangan"/>
                    </Col>
                    <Col xs={6} sm={3}>
                        <img onClick={() => props.history.push('/rewards')} src={RewardsImg}  alt="Rewards" className="pdImage d-block mx-auto img-fluid w-100 tangan"/>
                    </Col>
                    <Col xs={6} sm={3}>
                        <img onClick={() => props.history.push('/kupon')} src={KuponImg}  alt="Kupon" className="pdImage d-block mx-auto img-fluid w-100 tangan"/>
                    </Col>
                    <Col xs={6} sm={3}>
                        <img onClick={() => props.history.push('/outlet')} src={OutletsImg}  alt="Outlets" className="pdImage d-block mx-auto img-fluid w-100 tangan"/>
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    )
}

export default Home;