import React, { useState } from "react";
import {
    Toolbar, Typography, IconButton, AppBar, Container, Button
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import { Row, Col } from 'react-bootstrap';
import Geocode from "react-geocode";
import DialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';
import GoogleMapReact from 'google-map-react';
import Skeleton from 'react-loading-skeleton';
import Box from '@material-ui/core/Box';
import SearchIcon from '@material-ui/icons/Search';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';

Geocode.setApiKey(process.env.REACT_APP_KEY_MAP);
Geocode.setLanguage("id");
Geocode.setRegion("id");


const ModalOrder = (props) => {
    const classes = useStyles();
    const [center, setCenter] = useState({
        lat: props.coords.latitude,
        lng: props.coords.longitude
    })
    const [alamat, setAlamat] = useState(props.alamat)
    const [isLoadTitik, setIsLoadTitik] = useState(false)
    const [formCari, setFormCari] = useState("Search")
    const [isProses, setIsProses] = useState(false)



    const handleClose = () => {
        props.setOpen(false)
    }
    const cariLokasi = () => {
        if (formCari.length > 0) {
            setIsLoadTitik(true)
            Geocode.fromAddress(formCari).then(
                response => {
                    setCenter(response.results[0].geometry.location)
                    setAlamat(response.results[0].formatted_address)
                },
                error => {
                    console.error(error);
                }
            ).finally(() => setIsLoadTitik(false));
        }
        return

    };
    const renderMarkers = (map, maps) => {
        new maps.Marker({
            position: center,
            map,
            title: alamat
        });
    }
    const ubahTitik = titik => {
        setIsLoadTitik(true)
        setCenter(titik)
        setTimeout(() => {
            setIsLoadTitik(false)
        }, 500);
    }
    const selectLocation = () => {
        props.setCoords({
            latitude: center.lat,
            longitude: center.lng
        })
        Geocode.fromLatLng(center.lat, center.lng).then(
            response => {
                props.setAlamat(response.results[0].formatted_address)
            },
            error => {
                console.error(error);
            }
        ).finally(() => {
            setIsProses(false)
            handleClose()
        });
    }
    return (
        <Dialog fullScreen open={props.open} onClose={handleClose} TransitionComponent={Transition}>
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                        <CloseIcon />
                        <Typography variant="h6" className={classes.title}>Delivery Location</Typography>
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Container className="contentManualDialogFont bodyDialog" style={{ height: '100%', width: '100%' }}>
                <Row>
                    <Col xs={12}>
                        <Box display="flex">
                            <Box flexGrow={1} >
                                <TextField id="outlined-basic" label="Search" variant="outlined" fullWidth value={formCari} onChange={(x) => setFormCari(x.target.value)} />
                            </Box>
                            <Box width={80} style={{ paddingLeft: 2 }}> <Button onClick={cariLokasi} style={{ height: 56, marginTop: 5 }} variant="outlined" fullWidth color="inherit"> <SearchIcon /> Cari</Button></Box>
                        </Box>
                    </Col>
                    {isLoadTitik ? (
                        <Skeleton height={window.innerHeight - 280} />
                    ) : (
                            <Col xs={12} style={{ height: window.innerHeight - 200, width: '100%' }}>
                                <GoogleMapReact
                                    bootstrapURLKeys={{ key: process.env.REACT_APP_KEY_MAP }}
                                    defaultCenter={center}
                                    defaultZoom={17}
                                    yesIWantToUseGoogleMapApiInternals
                                    onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
                                    onClick={e => ubahTitik(e)}
                                >
                                </GoogleMapReact>
                            </Col>
                        )}

                </Row>
            </Container>
            <DialogActions>
                <Button onClick={selectLocation} disabled={isProses} variant="contained" fullWidth color="primary">{isProses ? <CircularProgress/>:"Select Location"} </Button>
            </DialogActions>
        </Dialog>
    )
}
export default ModalOrder;


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const useStyles = makeStyles(theme => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    root: {
        width: '100%',
        marginTop: theme.spacing(1),
        overflowX: 'auto',
        overflowY: 'hidden',
    },
}));