import React, { useContext, useEffect, useState } from 'react'
import AuthContext from '../../context/auth-context'
import { Row, Col } from 'react-bootstrap';
import { FaChevronLeft } from 'react-icons/fa'
import ListOutlet from '../Outlet/ListOutlet'
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import mOutlet from '../../Controller/outlet'
import ModalJenisOrder from './ModalJenisOrder'
import ModalOrder from './ModalOrder'



const OrderNow = (props) => {
    const authData = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(true)
    const [listOutlet, setListOutlet] = useState([])
    const [isFormOrder, setIsFormOrder] = useState(false)
    const [outletSelect, setOutletSelect] = useState({})
    const [isJenisOrder, setIsJenisOrder] = useState(false)

    useEffect(() => {
        mOutlet.listOutlet(authData.clientID, authData.token, authData.hp).then((res) => {
            setListOutlet(res)
        }).catch(err => {
            alert(err[0].message)
        }).finally(() => setIsLoading(false))
        return () => {

        }
    }, [authData.clientID, authData.token, authData.hp, isLoading])
    return (
        <React.Fragment>
            <Row>
                <Col xs={12}>
                    <h4 className="border-bottom paddingBottom5"><FaChevronLeft onClick={() => props.history.goBack()} className="btnBack" />Order Now</h4>
                    <h5 className="textColorprimary mgTop5">Apa yang anda suka hari ini?</h5>
                </Col>
            </Row>
            <Row>
                {!isLoading && listOutlet.length <= 0 && (
                    <Col xs={12} className="mgTop5">
                        <Card className="flex">
                            <CardContent>
                                <h6 className="text-center">Anda belum mendaftarkan outlet.<br />Klik <span onClick={() => props.history.push('/outlet')} className="textColorprimary"> di sini</span> untuk menambahkan outlet Anda.</h6>
                            </CardContent>
                        </Card>
                    </Col>
                )}
                <ListOutlet listOutlet={listOutlet} isLoading={isLoading} setOutletSelect={setOutletSelect} setIsJenisOrder={setIsJenisOrder}/>
                {isJenisOrder && (
                    <ModalJenisOrder open={isJenisOrder} setOpen={setIsJenisOrder} outletSelect={outletSelect} setIsFormOrder={setIsFormOrder}/>
                )}
                {isFormOrder && (
                    <ModalOrder  open={isFormOrder} setOpen={setIsFormOrder} outletSelect={outletSelect} authData={authData} urlBack={props.history.push}/>
                )}
            </Row>
        </React.Fragment>
    )
}

export default OrderNow;