import GQL from '../GQL';

const auth = {
    register: (email: String, namaLengkap: String, nohp: String, password: String, alamat: String, dof: String, gender: String) => {
        return new Promise((resolve, reject) => {
            const query = `
                        mutation{
                        registerweb(
                            input:{
                            username:"${nohp}",
                            email:"${email}",
                            password:"${password}",
                            nama:"${namaLengkap}",
                            alamat:"${alamat}",
                            dob:"${dof}",
                            gender:"${gender}"}){
                                success
                                info
                            }
                        }
                    `
            GQL(query, null, null).then(resp => {
                if (resp.errors) return (reject(resp.errors));;
                if (resp && resp.data && resp.data.registerweb != null) {
                    return (resolve(resp.data.registerweb));
                } else {
                    return (reject(new Error("Gagal menyimpan data")))
                }
            }).catch(err => reject(err));
        })
    },
    userDetail: (clientID: String, token: String,userLogin: String) => {
        return new Promise((resolve, reject) => {
            const query = `
                        query{
                            userDetail(username:"${userLogin}"){
                                urut
                                 username
                                 password
                                 salt
                                 nama
                                 alamat
                                 dob
                                 gender
                                 createdOn
                                 email
                             }
                        }
                    `
            GQL(query, clientID, token).then(resp => {
                if (resp.errors) return (reject(resp.errors));;
                if (resp && resp.data && resp.data.userDetail != null) {
                    return (resolve(resp.data.userDetail));
                } else {
                    return (reject(new Error("Gagal menyimpan data")))
                }
            }).catch(err => reject(err));
        })
    },
}

export default auth;