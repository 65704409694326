import React, { useContext, useState, useEffect } from 'react'
import AuthContext from '../../context/auth-context'
import mProfil from './Profil.Controler'
import { Row, Col } from 'react-bootstrap';
import {
    Button, TextField
} from '@material-ui/core';
import { isArray } from 'util'
import ModalInfo from '../../Dialog/Modal.Info'
import ModalChangePassword from './Modal.ChangePassword'
import { FaChevronLeft } from 'react-icons/fa'


const Profil = (props) => {
    const { clientID, token, userLogin } = useContext(AuthContext);
    const [isRefresh, setIsRefresh] = useState(true)
    const [modalText, setModalText] = useState()
    const [openInfo, setOpenInfo] = useState(false)
    const [openGantiPassword, setOpenGantiPassword] = useState(false)
    const [state, setState] = useState({
        email: '',
        noHp: '',
        nama: '',
    })
    useEffect(() => {
        if (isRefresh) {
            setIsRefresh(false)
            mProfil.loadProfil(clientID, token, userLogin).then(resp => {
                setState(resp)
            }).catch(err => {
                console.error(err);
            }).finally(() => setIsRefresh(false))
        }
        return () => {
        };
    }, [isRefresh, clientID, token, userLogin])
    const handleChange = (ev) => {
        var value = ev.target.value;
        const key = ev.target.id || ev.target.name;
        const newValue = { ...state };
        newValue[key] = value;
        setState(newValue);
    }

    const handleUpdate = () => {
        mProfil.updateProfil(clientID, token, state).then(resp => {
            if (resp) {
                setOpenInfo(true)
                setModalText(`<p>Data Berhasil Diupdate</p>`)
            } else {
                setOpenInfo(true)
                setModalText(`<p>Data Gagal Disimpan, harap isi semua data dengan benar</p>`)
            }
        }).catch(err => {
            if (isArray(err) && err.length > 0) {
                setOpenInfo(true)
                setModalText(`<p>Gagal Simpan :  ${err[0].message}</p>`)
            } else if (err === 'error') {
                setOpenInfo(true)
                setModalText(`<p>${err.message}</p>`)
            } else {
                console.log(err);
            }
        })
    }
    const handleUbahPassword = () => {
        setOpenGantiPassword(true)
    }
    const goBack = () => {
        props.history.push(`/`)
    }
    return (
        <React.Fragment>
            <Row>
                <Col xs={12}>
                    <h5 className="border-bottom paddingBottom5"><FaChevronLeft onClick={goBack.bind(this)} className="btnBack" />Profil</h5>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <TextField
                        id="Email"
                        name="Email"
                        label="E-mail (read Only)"
                        fullWidth
                        value={state.email}
                        variant="outlined"
                        disabled
                    />
                </Col>
                <Col xs={12}>
                    <TextField
                        id="nama"
                        name="nama"
                        label="Nama Lengkap"
                        fullWidth
                        value={state.nama}
                        variant="outlined"
                        onChange={handleChange}
                    />
                </Col>
                <Col xs={12}>
                    <TextField
                        id="noHp"
                        name="noHp"
                        label="No Hp"
                        fullWidth
                        value={state.noHp}
                        variant="outlined"
                        onChange={handleChange}
                    />
                </Col>
                <Col xs={12} md={6}>
                    <span className="gantiPassword" onClick={handleUbahPassword}> Change Password <small>(Klik disini)</small></span>
                </Col>
                <Col xs={12}>
                    <Button
                        type="button"
                        fullWidth
                        variant="contained"
                        className="submit"
                        onClick={handleUpdate}
                    >
                        Update Profil</Button>
                </Col>
            </Row>
            {openInfo && (
                <ModalInfo open={openInfo} setOpen={setOpenInfo} text={modalText} closeModal={props.setOpen} />
            )}
            {openGantiPassword && (
                <ModalChangePassword open={openGantiPassword} setOpen={setOpenGantiPassword} data={state} />
            )}
        </React.Fragment>
    )
}

export default Profil;