import React, { useState, useEffect } from "react";
import {
    Toolbar, Typography, IconButton, AppBar, Container, Button, ButtonGroup
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Box from '@material-ui/core/Box';
import { Row, Col } from 'react-bootstrap';
import noImage from '../../Data/Image/noImage.png'
import Skeleton from 'react-loading-skeleton';
import mOrder from '../../Controller/order'
import Card from '@material-ui/core/Card';
import RoomIcon from '@material-ui/icons/Room';
import NoteIcon from '@material-ui/icons/Note';
import TextField from '@material-ui/core/TextField';
import numberWithCommasEmpty from '../../Helper/numberWithCommasEmpty'
import CreateIcon from '@material-ui/icons/Create';
import Geolocation from 'react-geolocation'
import Geocode from "react-geocode";
import ModalCatatan from './ModalCatatan'
import CircularProgress from '@material-ui/core/CircularProgress';
import DialogInfo from '../../Dialog/DialogInfo'
import ModalMap from './Modal.Map'

Geocode.setApiKey(process.env.REACT_APP_KEY_MAP);
Geocode.setLanguage("id");
Geocode.setRegion("id");


const ModalOrder = (props) => {
    const classes = useStyles();
    const outlet = props.outlet
    const { latitude, longitude } = outlet
    const authData = props.authData
    const [isCekOngkir, setIsCekOngkir] = useState(false)
    const [shoppingCart, setShoppingCart] = useState(props.shoppingCart)
    const [coords, setCoords] = useState({
        latitude: -6.2295712,
        longitude: 106.7594779
    })
    const [alamat, setAlamat] = useState('')
    const [keterangan, setKeterangan] = useState('')
    const [distance, setDistance] = useState({
        text: '',
        value: 0
    })
    const [duration, setDuration] = useState({
        text: '',
        value: 0
    })
    const [ongkir, setOngkir] = useState(0)
    const [stateCatatan, setStateCatatan] = useState({
        visible: false,
        item: {}
    })
    const [isSimpan, setIsSimpan] = useState(false)
    const [isAlert, setIsAlert] = useState(false);
    const [textAlert, setTextAlert] = useState('');
    const [isMap, setIsMap] = useState(false)


    const handleBack = () => {
        props.setIsOrder(false)
    }
    useEffect(() => {
        mOrder.calculateOngkir(authData.clientID, authData.token, outlet.kodeoutlet, distance.value).then(({ success, info, ongkir }) => {
            if (success) {
                setOngkir(ongkir)
            } else {
                setOngkir(0)
                alert("Gagal menghitung ongkos kirim")
            }
        }).catch(console.log).finally(() => setIsCekOngkir(false))
        return () => {

        }
        //eslint-disable-next-line
    }, [distance])
    const getCurrentPosition = position => {
        // Geocode.fromAddress("Jahanjang").then(
        //     response => {
        //       console.log(response)
        //     },
        //     error => {
        //       console.error(error);
        //     }
        //   );
        setCoords({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude
        })
        Geocode.fromLatLng(position.coords.latitude, position.coords.longitude).then(
            response => {
                setAlamat(response.results[0].formatted_address)
                mOrder.calculateDistance(authData.clientID, authData.token, position.coords.latitude, position.coords.longitude, latitude, longitude).then((res) => {
                    const { success, info, distanceText, distanceValue, durationText, durationValue } = res
                    if (success) {
                        setDistance({
                            text: distanceText,
                            value: distanceValue
                        })
                        setDuration({
                            text: durationText,
                            value: durationValue
                        })
                    } else {
                        alert(info)
                    }
                }).catch(err => {
                    alert(err[0].message)
                })
            },
            error => {
                console.error(error);
            }
        )
    };
    const subtotal = shoppingCart.map(el => el.jumlah).reduce((a, b) => a + b, 0)
    const serviceAmount = subtotal * outlet.service / 100
    const taxAmount = (subtotal + serviceAmount) * outlet.tax / 100
    const beforeOngkir = subtotal + serviceAmount + taxAmount
    const handlePlus = (item) => {
        const newCart = shoppingCart.map(el => {
            if (el.kodebarang === item.kodebarang) {
                const qty = +el.qty + 1
                const jumlah = qty * el.harga
                return {
                    ...el,
                    qty,
                    jumlah
                }
            }
            return el
        })
        setShoppingCart(newCart)
    }

    const handleMinus = (item) => {
        if (item.qty === 1) {
            const newCart = shoppingCart.filter(el => el.kodebarang !== item.kodebarang)
            setShoppingCart(newCart)
        } else {
            const newCart = shoppingCart.map(el => {
                if (el.kodebarang === item.kodebarang) {
                    const qty = +el.qty - 1
                    const jumlah = qty * el.harga
                    return {
                        ...el,
                        qty,
                        jumlah
                    }
                }
                return el
            })
            setShoppingCart(newCart)
        }
    }

    const handleCatatan = (item) => {
        setStateCatatan({
            visible: true,
            item
        })
    }
    const handleCatatanChange = (catatan) => {
        const newShoppingCart = shoppingCart.map(el => {
            if (el.kodebarang.toUpperCase() === stateCatatan.item.kodebarang.toUpperCase()) {
                return {
                    ...el,
                    catatan
                }
            }
            return el
        })
        setShoppingCart(newShoppingCart)
        setStateCatatan({
            visible: false,
            item: {}
        })
    }
    const onCloseCatatan = () => {
        setStateCatatan({
            visible: false,
            item: {}
        })
    }
    const handleOrder = () => {
        setIsSimpan(true)
        mOrder.simpanOrder(authData.clientID, authData.token, shoppingCart, coords.latitude, coords.longitude, keterangan, ongkir, 'CASH', outlet.kodeoutlet, alamat, distance.value, outlet.tax, taxAmount, outlet.service, serviceAmount, authData.hp).then((res) => {
            if (res.success) {
                setTextAlert("Pesanan Anda Berhasil di Order")
                setIsAlert(true)
            } else {
                alert(res.info)
            }
        }).catch(err => {
            alert(err[0].message)
        }).finally(() => setIsSimpan(false))
    }
    return (
        <>
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={handleBack} aria-label="close">
                        <ArrowBackIcon />
                        <Typography variant="h6" className={classes.title}>{outlet.namaoutlet}</Typography>
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Container className="DialogFont bodyDialog">
                {isSimpan ? (
                    <h6 className="text-center">Sedang Proses Order <br />  <CircularProgress color="secondary" /></h6>
                ) : (
                        <Row>
                            <Col xs={12} className="mgTop5">
                                <Card className="cardOrderPay">
                                    <Row className="width100">
                                        <Col xs={12}>
                                            <Box display="flex" onClick={() => setIsMap(true)}>
                                                <Box width={25}> <RoomIcon /></Box>
                                                <Box flexGrow={1} >
                                                    <span className="headCart">Delivery Location</span><br />
                                                    <span>{alamat}</span>
                                                </Box>
                                            </Box>
                                        </Col>
                                        <Col xs={12}>
                                            <Box display="flex">
                                                <Box width={25}> <NoteIcon style={{ marginTop: 5 }} /></Box>
                                                <Box flexGrow={1} >
                                                    <TextField fullWidth placeholder="Notes" value={keterangan} onChange={(c) => setKeterangan(c.target.value)} />
                                                </Box>
                                            </Box>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                            <Col xs={12} className="mgTop5">
                                <Card className="cardOrderPay">
                                    <Row className="width100">
                                        <Col xs={12}>
                                            <Box display="flex">
                                                <Box flexGrow={1} >
                                                    <span className="headCart">Order Item(s)</span>
                                                </Box>
                                            </Box>
                                        </Col>

                                        {shoppingCart.map((item, i) => (
                                            <Col xs={12} key={i}>
                                                <Row>
                                                    <Col xs={3}>
                                                        <img src={item.gambar || noImage} alt="Outlet" className="d-block mx-auto img-fluid w-100 pd10TB" />
                                                    </Col>
                                                    <Col xs={9}>
                                                        <Box display="flex">
                                                            <Box flexGrow={1} >
                                                                <span>{item.namabarang}</span><br />
                                                                <span>{numberWithCommasEmpty(item.harga)}</span><br />
                                                                <ButtonGroup size="small" aria-label="small outlined button group">
                                                                    <Button onClick={handleCatatan.bind(this, item)} color={(item.catatan || '').length > 0 ? "primary" : "default"}><CreateIcon style={{ height: 17 }} /></Button>
                                                                </ButtonGroup>
                                                            </Box>
                                                            <Box width={88}>
                                                                <span className="text-right">
                                                                    <ButtonGroup size="small" aria-label="small outlined button group">
                                                                        <Button onClick={handleMinus.bind(this, item)}>-</Button>
                                                                        <Button disabled> {item.qty}</Button>
                                                                        <Button onClick={handlePlus.bind(this, item)}>+</Button>
                                                                    </ButtonGroup>
                                                                </span>
                                                            </Box>
                                                        </Box>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        ))}
                                        <Col xs={12}>
                                            <Box display="flex">
                                                <Box flexGrow={1} >
                                                    <small className="textColorprimary tangan" onClick={handleBack} >Add More Item(s)</small>
                                                </Box>
                                            </Box>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                            <Col xs={12} className="mgTop5">
                                <Card className="cardOrderPay">
                                    <Row className="width100">
                                        <Col xs={12}>
                                            <Box display="flex">
                                                <Box flexGrow={1} >
                                                    <b> {outlet.tax > 0 || outlet.service > 0 ? 'VAT OUT' : 'SUB TOTAL'}:</b>
                                                </Box>
                                                <Box width={20}>
                                                    <b className="text-right">{numberWithCommasEmpty(subtotal)}</b>
                                                </Box>
                                            </Box>
                                        </Col>
                                        {outlet.service > 0 && (
                                            <Col xs={12}>
                                                <Box display="flex">
                                                    <Box flexGrow={1} >
                                                        <span> SERVICE:</span>
                                                    </Box>
                                                    <Box width={20}>
                                                        <span className="text-right">{numberWithCommasEmpty(serviceAmount)}</span>
                                                    </Box>
                                                </Box>
                                            </Col>
                                        )}
                                        {outlet.tax > 0 && (
                                            <Col xs={12}>
                                                <Box display="flex">
                                                    <Box flexGrow={1} >
                                                        <span> TAX:</span>
                                                    </Box>
                                                    <Box width={20}>
                                                        <span className="text-right">{numberWithCommasEmpty(taxAmount)}</span>
                                                    </Box>
                                                </Box>
                                            </Col>
                                        )}
                                        {(outlet.tax > 0 || outlet.service > 0) && (
                                            <Col xs={12}>
                                                <Box display="flex">
                                                    <Box flexGrow={1} >
                                                        <b> VAT IN:</b>
                                                    </Box>
                                                    <Box width={20}>
                                                        <b className="text-right">{numberWithCommasEmpty(beforeOngkir)}</b>
                                                    </Box>
                                                </Box>
                                            </Col>
                                        )}
                                        <Col xs={12}>
                                            <Box display="flex">
                                                <Box flexGrow={1} >
                                                    <span>Delivery ({isCekOngkir ? <Skeleton width={25} /> : <>{distance.text} - {duration.text}</>}):</span>
                                                </Box>
                                                <Box width={20}>
                                                    <span className="text-right">{numberWithCommasEmpty(ongkir)}</span>
                                                </Box>
                                            </Box>
                                        </Col>
                                        <Col xs={12}>
                                            <Box display="flex">
                                                <Box flexGrow={1} >
                                                    <b>  <span>Total</span></b>
                                                </Box>
                                                <Box width={20}>
                                                    <b className="text-right">{numberWithCommasEmpty(beforeOngkir + ongkir)}</b>
                                                </Box>
                                            </Box>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                            <Col xs={12} className="mgTop5">
                                <Card className="cardOrderPay">
                                    <Row className="width100">
                                        <Col xs={12}>
                                            <Box display="flex">
                                                <Box flexGrow={1} >
                                                    <span>Payment</span>
                                                </Box>
                                                <Box width={20}>
                                                    <span className="text-right">CASH</span>
                                                </Box>
                                            </Box>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                    )}
                <Row className="bgB60"></Row>
                <Geolocation
                    onSuccess={getCurrentPosition}
                />
                <div className={classes.btnOrder}>
                    <Button disabled={isSimpan || isCekOngkir} style={{ marginTop: '5px' }} variant="contained" color="primary" fullWidth onClick={handleOrder}>Order </Button>
                </div>
                {stateCatatan.visible && (
                    <ModalCatatan open={stateCatatan.visible} catatan={stateCatatan.item.catatan || ''} close={onCloseCatatan} handleCatatanChange={handleCatatanChange} />
                )}
                {isAlert && (
                    <DialogInfo open={isAlert} setOpen={setIsAlert} text={textAlert} url={"/order"} redirect={props.urlBack}/>
                )}
                {isMap && <ModalMap  open={isMap} setOpen={setIsMap} setAlamat={setAlamat} setCoords={setCoords} coords={coords} alamat={alamat}/>}
            </Container>
        </>
    )
}
export default ModalOrder;
const useStyles = makeStyles(theme => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    root: {
        width: '100%',
        marginTop: theme.spacing(1),
        overflowX: 'auto',
        overflowY: 'hidden',
    },
    btnOrder: {
        margin: theme.spacing(),
        position: "fixed",
        bottom: theme.spacing(1),
        zIndex: 2,
        width: window.innerWidth - 50
    },
}));