import React, { useContext, useState } from "react";
import {
    Toolbar, Typography, IconButton, Dialog, AppBar, Slide, Container, TextField, Button
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close'
import { makeStyles } from '@material-ui/core/styles';
import mProfil from './Profil.Controler'
import AuthContext from '../../context/auth-context'
import { Row, Col } from "react-bootstrap";
import sha256 from 'sha256'
import ModalInfo from '../../Dialog/Modal.Info'
import { isArray } from 'util'



const DialogGantiPassword = (props) => {
    const data = props.data
    const { clientID, token } = useContext(AuthContext);
    const [modalText, setModalText] = useState()
    const [openInfo, setOpenInfo] = useState(false)
    const classes = useStyles();
    const [statusSave, setStatusSave] = useState(false)
    const [state, setState] = useState({
        oldPassword: '',
        newPassword: '',
        newPassword2: '',
    })

    const handleClose = () => {
        props.setOpen(false);
    }

    const handleChange = (ev) => {
        var value = ev.target.value;
        const key = ev.target.id || ev.target.name;
        const newValue = { ...state };
        newValue[key] = value;
        setState(newValue);
    }
    const handleUpdate = () => {
        const cekPassLama = sha256(`${data.salt}${state.oldPassword}${data.salt}`);
        if(state.newPassword === '' || state.newPassword2 === '' || state.oldPassword === ''){
           setOpenInfo(true)
            setModalText(`<p>Wajib isi semua form !</p>`)
            return
        }
        if(cekPassLama !== data.password){
            setOpenInfo(true)
            setModalText(`<p>Password lama yang anda masukan salah</p>`)
            return
        }else if(state.newPassword !== state.newPassword2){
            setOpenInfo(true)
            setModalText(`<p>Password baru yang anda masukan tidak sama</p>`)
            return
        }else{
            mProfil.updatePassword(clientID, token, data.salt, data.email, state.newPassword).then(resp => {
                if (resp) {
                    setOpenInfo(true)
                    setModalText(`<p>Password Berhasil Diupdate</p>`)
                    setStatusSave(true)
                } else {
                    setOpenInfo(true)
                    setModalText(`<p>Data Gagal Disimpan, harap isi semua data dengan benar</p>`)
                }
            }).catch(err => {
                if (isArray(err) && err.length > 0) {
                    setOpenInfo(true)
                    setModalText(`<p>Gagal Simpan :  ${err[0].message}</p>`)
                } else if (err === 'error') {
                    setOpenInfo(true)
                    setModalText(`<p>${err.message}</p>`)
                } else {
                    console.log(err);
                }
            })
        }
    }
    return (
        <Dialog fullScreen open={props.open} TransitionComponent={Transition}>
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" aria-label="close" onClick={handleClose} >
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        Change Password</Typography>
                </Toolbar>
            </AppBar>
            <Container className="DialogFont bodyDialog">
                <Row>
                    <Col xs={12}>
                        <TextField
                            id="oldPassword"
                            name="oldPassword"
                            label="Masukan Password Lama Anda"
                            fullWidth
                            value={state.oldPassword}
                            variant="outlined"
                            onChange={handleChange}
                            type="password"
                        />
                        <TextField
                            id="newPassword"
                            name="newPassword"
                            label="Masukan Password Baru Anda"
                            fullWidth
                            value={state.newPassword}
                            variant="outlined"
                            onChange={handleChange}
                            type="password"
                        />
                        <TextField
                            id="newPassword2"
                            name="newPassword2"
                            label="Masukan Ulang Password Baru Anda"
                            fullWidth
                            value={state.newPassword2}
                            variant="outlined"
                            onChange={handleChange}
                            type="password"
                        />
                    </Col>
                    <Col xs={12}>
                        <Button
                            type="button"
                            fullWidth
                            variant="contained"
                            className="submit"
                            onClick={handleUpdate}
                        >
                            Update Password</Button>
                    </Col>
                </Row>
                {openInfo && (
                    <ModalInfo open={openInfo} setOpen={setOpenInfo} text={modalText} closeModal={props.setOpen} statusSave={statusSave}/>
                )}
            </Container>
        </Dialog>
    )
}
export default DialogGantiPassword;
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const useStyles = makeStyles(theme => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    root: {
        width: '100%',
        marginTop: theme.spacing(1),
        overflowX: 'auto',
        overflowY: 'hidden',
    },
}));