import React from 'react'
//import React, { useContext, useEffect, useState } from 'react'
// import AuthContext from '../../context/auth-context'
import { Row, Col } from 'react-bootstrap';
import construction from '../../Data/Image/construction.png'



const Kupon = (props) => {
  //const { clientID, token, userLogin } = useContext(AuthContext);


  return (
    <React.Fragment>
      <Row>
        <Col>  <img src={construction} alt="construction" className="d-block mx-auto img-fluid" /></Col>
      </Row>
    </React.Fragment>
  )
}

export default Kupon;