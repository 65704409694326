import GQL from '../GQL';
import moment from 'moment-timezone'

const Order = {
    listMenu: (clientID, token, username, kodeoutlet) => {
        return new Promise((resolve, reject) => {
            const query = `
                        query{
                            listMenuWeb(kodeoutlet:"${kodeoutlet}",username:"${username}"){
                                urut
                                namakategori
                                kodebarang
                                namabarang
                                harga
                                gambar
                                manajemenPrinter
                            }
                        }
                    `
            GQL(query, clientID, token).then(resp => {   
                if (resp.errors) return (reject(resp.errors));;
                if (resp && resp.data && resp.data.listMenuWeb) {
                    const listMenu = resp.data.listMenuWeb
                    const listKategori = []
                    listMenu.reduce((a, b) => {
                        if (!a[b.namakategori]) {
                            a[b.namakategori] = {
                                namakategori: b.namakategori,
                            }
                            listKategori.push(a[b.namakategori])
                        }
                        return a;
                    }, {})
                    return (resolve({ listMenu, listKategori }));
                } else {
                    return (reject(new Error("Gagal load data")))
                }
            }).catch(err => reject(err));
        })
    },
    calculateDistance: (clientID, token, lat1, lng1, lat2, lng2) => {
        return new Promise((resolve, reject) => {
            const query = `
                         mutation{
                             checkDistance(input:{origin:{lat:"${lat1}",lng:"${lng1}"},destination:{lat:"${lat2}",lng:"${lng2}"}}){ 
                            success 
                            info
                            distanceText
                            distanceValue
                            durationText
                            durationValue }    
                        }
                    `
            GQL(query, clientID, token).then(resp => {
                if (resp.errors) return (reject(resp.errors));;
                if (resp && resp.data && resp.data.checkDistance) {
                    return (resolve(resp.data.checkDistance));
                }
                else if (resp && resp.errors && resp.errors.length > 0) {
                    const [error] = resp.errors
                    console.log(error.message)
                    return resolve({ success: false, info: error.messasge })
                } else return resolve({ success: false, info: "No responses" })
            }).catch(err => reject(err));
        })
    },
    calculateOngkir: (clientID, token, kodeoutlet, distance) => {
        return new Promise((resolve, reject) => {
            const query = `
                         mutation{
                            hitungOngkir(kodeoutlet:"${kodeoutlet}",jarak:${distance}){
                                success
                                info
                                ongkir
                            }  
                        }
                    `
            GQL(query, clientID, token).then(resp => {
                if (resp.errors) return (reject(resp.errors));;
                if (resp && resp.data && resp.data.hitungOngkir) {
                    return (resolve(resp.data.hitungOngkir));
                } else if (resp && resp.errors && resp.errors.length > 0) {
                    const [error] = resp.errors
                    console.log(error.message)
                    return resolve({ success: false, info: error.messasge })
                } else return resolve({ success: false, info: "No responses" })
            }).catch(err => reject(err));
        })
    },
    simpanOrder: (clientID, token, listMenu, latitude, longitude, catatanAlamat, ongkir, payment, kodeoutlet, alamat, jarak, tax, taxAmount, service, serviceAmount, username) => {
        return new Promise((resolve, reject) => {
            const query = `
                         mutation{
                            simpanOrderanNew(input:{tanggal:"${moment.tz("Asia/Jakarta").format("YYYY-MM-DD HH:mm:ss")}",kodeoutlet:"${kodeoutlet}",kodepayment:"${payment}",deliveryAddress:"${alamat}",deliveryAddressNote:"${catatanAlamat}",deliveryLat:${latitude},deliveryLng:${longitude},deliveryDistance:${jarak},deliveryCost:${ongkir},userOrder:"${username}",tax:${tax},taxAmount:${taxAmount},service:${service},serviceAmount:${serviceAmount},item:${JSON.stringify(JSON.stringify(listMenu))}}){
                                success
                                info
                            }
                        }
                    `
            GQL(query, clientID, token).then(resp => {
                if (resp.errors) return (reject(resp.errors));;
                if (resp && resp.data && resp.data.simpanOrderanNew) {
                    return (resolve(resp.data.simpanOrderanNew));
                } else if (resp && resp.errors && resp.errors.length > 0) {
                    const [error] = resp.errors
                    console.log(error.message)
                    return resolve({ success: false, info: error.messasge })
                } else return resolve({ success: false, info: "No responses" })
            }).catch(err => reject(err));
        })
    },
    riwayatOrder: (clientID, token, username) => {
        return new Promise((resolve, reject) => {
            const query = `
                            query{
                                listOrderanWeb(input:{tanggalDari:"${moment.tz("Asia/Jakarta").add(-7, 'day').format("YYYY-MM-DD")}",tanggalSampai:"${moment.tz("Asia/Jakarta").format("YYYY-MM-DD")}"}, username:"${username}"){
                                kodeorderan
                                tanggal
                                outlet{
                                    kodeoutlet
                                    namaoutlet
                                    gambar
                                }
                                item{
                                    kodebarang
                                    namabarang
                                    qty
                                    harga
                                    jumlah
                                }
                                statusid
                            }
                        }
                    `
            GQL(query, clientID, token).then(resp => {
                if (resp.errors) return (reject(resp.errors));;
                if (resp && resp.data && resp.data.listOrderanWeb) {
                    return (resolve(resp.data.listOrderanWeb));
                } else if (resp && resp.errors && resp.errors.length > 0) {
                    const [error] = resp.errors
                    console.log(error.message)
                    return resolve({ success: false, info: error.messasge })
                } else return resolve([])
            }).catch(err => reject(err));
        })
    },
    setDelivered: (clientID, token, kodeoutlet, kodeorderan) => {
        return new Promise((resolve, reject) => {
            const query = `
                         mutation{
                            setDelivered(kodeoutlet:"${kodeoutlet}",kodeorderan:"${kodeorderan}"){
                                    success
                                    info
                                }
                        }
                    `
            GQL(query, clientID, token).then(resp => {
                if (resp.errors) return (reject(resp.errors));;
                if (resp && resp.data && resp.data.setDelivered) {
                    return (resolve(resp.data.setDelivered));
                } else if (resp && resp.errors && resp.errors.length > 0) {
                    const [error] = resp.errors
                    console.log(error.message)
                    return resolve({ success: false, info: error.messasge })
                } else return resolve({ success: false, info: "No responses" })
            }).catch(err => reject(err));
        })
    },
}

export default Order;