import React, { useState, useEffect } from "react";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import QRCode from 'qrcode.react'
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Skeleton from 'react-loading-skeleton';
import mControl from '../Controller/auth'

type ModalQRProps = {
    clientID: String,
    open: boolean,
    setOpen: Function,
    token: String,
    userLogin: String
}

const ModalQR = (props: ModalQRProps) => {
    const [isLoading, setIsLoading] = useState(true)
    const [user, setUser] = useState<any>({})

    useEffect(() => {
        mControl.userDetail(props.clientID, props.token, props.userLogin).then((res: any) => {
            setUser(res)
        }).catch(err => {
            alert(err[0].message)
        }).finally(() => setIsLoading(false))
        return () => {

        }
    }, [props.clientID, props.token, props.userLogin])
    const handleClose = () => {
        props.setOpen(false);
    };

    return (
        <Dialog open={props.open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">QR Anda</DialogTitle>
            <DialogContent>
                <Card >
                    <CardContent className="text-center">
                        {isLoading ? (
                            <Skeleton height={250} />
                        ) : (
                                <span>
                                    <h6 className="text-center"><b>{user.email}</b></h6>
                                    <h6 className="text-center"><b>{user.username}</b></h6>
                                    <QRCode value={user.username} size={250} /><br />
                                    <small>Scan QR Code di Kasir</small>
                                </span>
                            )}
                    </CardContent>
                </Card>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Close</Button>
            </DialogActions>
        </Dialog>
    );
};

export default ModalQR;

