import React from 'react'
import { Col } from 'react-bootstrap';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import noImage from '../../Data/Image/noImage.png'
import Skeleton from 'react-loading-skeleton';



const ListOutlet = (props) => {
    const rowLoad = [0, 1, 2]
    const selectOrder = (data) => {
        if (props.setOutletSelect) {
            props.setIsJenisOrder(true)
            props.setOutletSelect(data)
        } else return
    }
    return (
        <React.Fragment>
            {!props.isLoading && props.listOutlet.map((el, i) => (
                <Col xs={12} key={i} className="mgTop5 tangan" onClick={selectOrder.bind(this,el)}>
                    <Card className="flex">
                        <Col xs={4}>
                            <img src={el.outlet.gambar || noImage} alt="Outlet" className="d-block mx-auto img-fluid imgOutlet" />
                        </Col>
                        <Col xs={8}>
                            <CardContent>
                                <h5>{el.outlet.namaoutlet}</h5>
                                <span>{el.outlet.address}</span><br />
                                <span>{el.outlet.phoneNumber}</span>
                            </CardContent>
                        </Col>
                    </Card>
                </Col>
            ))}
            {props.isLoading && rowLoad.map((el, i) => (
                <Col xs={12} key={i} className="mgTop5">
                    <Card className="flex">
                        <Col xs={4}>
                            <Skeleton height={110} style={{ margin: 10 }} />
                        </Col>
                        <Col xs={8}>
                            <CardContent>
                                <h5> <Skeleton width={180} /></h5>
                                <span><Skeleton width={160} /></span><br />
                                <span><Skeleton width={140} /></span>
                            </CardContent>
                        </Col>
                    </Card>
                </Col>
            ))}
        </React.Fragment>
    )
}

export default ListOutlet;