import GQL from '../GQL';

const outlet = {
    listOutlet: (clientID, token, username) => {
        return new Promise((resolve, reject) => {
            const query = `
                        query{
                            listOutletWeb(username:"${username}"){
                                id
                                username
                                outlet{
                                  kodeoutlet
                                  namaoutlet
                                  address
                                  phoneNumber
                                  gambar
                                  gojek
                                  grab
                                  latitude
                                  longitude
                                  tax
                                  service
                                }
                            }
                        }
                    `
            GQL(query, clientID, token).then(resp => {
                if (resp.errors) return (reject(resp.errors));;
                if (resp && resp.data && resp.data.listOutletWeb) {
                    return (resolve(resp.data.listOutletWeb));
                } else {
                    return (reject(new Error("Gagal load data")))
                }
            }).catch(err => reject(err));
        })
    },
    simpanOutlet: (clientID, token, username, kodeoutlet) => {
        return new Promise((resolve, reject) => {
            const query = `
                        mutation{
                            registerOutletWeb(kodeoutlet:"${kodeoutlet}", username:"${username}"){
                                success
                                info
                            }
                        }
                    `
            GQL(query, clientID, token).then(resp => {
                if (resp.errors) return (reject(resp.errors));
                if (resp && resp.data && resp.data.registerOutletWeb) {
                    return (resolve(resp.data.registerOutletWeb));
                } else {
                    return (reject(new Error("Gagal simpan data")))
                }
            }).catch(err => reject(err));
        })
    },
}

export default outlet;